.watchlist-details-container {
  flex-direction: row;
  min-width: 1008px;
}

.watchlist-details-content-container {
  width: 1008px;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.watchlist-details-content {
  width: 70%;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
}

.watchlist-details-lists {
  width: 30%
}

.watchlist-details-name {
  font-size: larger;
}

.watchlist-details-name-buttons {
  display: flex;
  justify-content: space-between;
}

.watchlist-details-item-count {
  font-size: small;
  color: gray;
  margin-top: 10px;
  margin-bottom: 20px
}

.watchlist-details-list-header-name {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 13px;

}

.watchlist-details-list-header,
.watchlist-details-list-header-symbol {
  text-align: left;
  font-size: 13px;
}

.watchlist-details-list-header-market-cap {
  text-align: left;
  font-size: 13px;
  /* display: flex; */
}

.watchlist-details-table {
  display: flex;
  flex-direction: column;
  
}

.watchlist-details-table-header {
  border-bottom: 1px solid rgb(227,233,237);
  display: flex;
  align-items: center;
  align-content: center;
}

.watchlist-details-list-header-name {
  width: 40%
}

.watchlist-details-list-header-symbol {
  width: 12%
}

.watchlist-details-list-header-price {
  width: 15%;
  font-size: 13px;
}

.watchlist-details-list-header-change {
  width: 18%;
  font-size: 13px;
}

.watchlist-details-list-header-market-cap {
  width: 15%
}

.watchlist-details-list-body {
  font-size: 13px;
}


#hello {
  display: flex;
  padding-top: 18px;
  justify-content: space-between;
}

.watchlist-details-list-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid rgb(227,233,237);
}

.watchlist-details-list-name {
  width: 40%;
  max-width: 40%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.watchlist-details-list-symbol {
  width: 12%
}

.watchlist-details-list-price {
  width: 15%;
}

.watchlist-details-list-today {
  width: 18%;
}

.watchlist-details-list-header-market-cap-delete {
  width: 15%;
  display: flex;
  align-items: center;
}

.watchlist-details-list-market-cap {
  width: 85%;
}

.watchlist-details-list-row:hover {
  background-color: rgb(245,248,250);
  cursor: pointer;
}

.watchlist-details-stock-delete-button {
  cursor:inherit;
  padding: 5px;
  border-radius: 5px;
}

.watchlist-details-stock-delete-button:hover {
  background-color: rgb(252, 179, 179);
}

.watchlist-details-buttons-conatiner {
  display: flex;
  column-gap: 15px;
  font-size: large;
  align-items: end;
  padding-right: 10px;
}

.watchlist-details-filters-button,
.watchlist-details-more-button {
  padding: 5px;
  border-radius: 5px;
}

.watchlist-details-more-button:hover,
.watchlist-details-filters-button:hover {
  background-color: rgb(227,233,237);
}

.watchlist-details-triangle {
  height: 10px;
  margin-right: 5px;
}
