.completion-message {
  color: #6a7278;
  font-size: 13px;
  padding: 20px 0px;
}

.confirmation-title {
  padding: 20px;
}

.dollar-amount {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.done-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.shares {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.share-price {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.transaction-details {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 400;
  padding: 10px 20px;
  max-width: 100%;
}

.done-button {
  width: 200px;
  height: 40px;
  background-color: rgb(0,200,6);
  color: white;
  border-radius: 50px;
  border: none;
  font-size: 10pt;
  font-weight: bold;
  margin-bottom: 25px;
}
