.watchlist-button {
  width: 200px;
  height: 40px;
  background-color: white;
  color: rgb(0,200,6);
  border-radius: 50px;
  border: 1px solid rgb(0,200,6);
  font-size: 10pt;
  font-weight: bold;
}

.add-watchlist-modal-container {
  height: 300px;
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 5px;
}

.add-watchlist-modal-title-exit {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  font-size: 17px;
  font-weight: bold;
}

.add-watchlist-modal-exit {
  cursor: pointer;
}

.add-watchlist-modal-lists {
  display: flex;
  flex-direction: column;
  max-height: 210px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.watchlist-lists {
  padding: 12px;
  display: flex;
  align-items: center;

}



.checkbox {
  position: relative;
  display: inline-block;
  font-size: 0;
  cursor: pointer;
}

.checkbox input[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.checkbox-custom {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-right: 10px;
  vertical-align: middle;
}

.checkbox-custom:before {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  background-color: rgb(0,200,6);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.1s ease-in-out;
}

.checkbox input[type='checkbox']:checked + .checkbox-custom:before {
  transform: translate(-50%, -50%) scale(1);
}

.checkbox-label {
  font-size: 16px;
  vertical-align: middle;
}

.add-watchlist-submit-button {
  width: 275px;
  height: 40px;
  margin: 10px;
  background-color: rgb(178, 187, 189);
  color: black;
  border-radius: 50px;
  border: none;
  font-size: 10pt;
  font-weight: bold;
  cursor: pointer;
}

.add-watchlist-submit-button.active {
  background-color: rgb(0,200,6);
  color: white;
}
