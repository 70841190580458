.active-type {
    color: #01a504;
    border-bottom: solid 2px #01a504;
}

.button {
    width: 200px;
    height: 40px;
    background-color: rgb(0,200,6);
    color: white;
    border-radius: 50px;
    border: none;
    font-size: 10pt;
    font-weight: bold;
}

.button:hover {
    background-color: #01a504;
}

.cash {
    color: #e69a00;
}

.cash-and-deposit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.cash-header-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.deposit-button {
    color: white;
    background-color: #00C806;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
}

.deposit-button:hover {
    background-color: #01a504;
}

.deposit-container {
    display: flex;
    column-gap: 15px;
    margin-bottom: 20px;
    overflow: hidden;
    max-height: 1000px; /* Set a max-height large enough to accommodate the content */
    transition: max-height 3s ease-out;
}
  
.deposit-hidden {
    max-height: 0;
    margin-bottom: 0;
    transition: max-height 0.01s ease-in;
}
  

.deposit-container > button {
  background: #00C806;
  border: 0;
  border-radius: 10px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.deposit-container > button:not([disabled]):focus {
  box-shadow: 0 0 .25rem #00fd04, -.125rem -.125rem 1rem #00fd04, .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.deposit-container > button:not([disabled]):hover {
  box-shadow: 0 0 .25rem #00fd04, -.125rem -.125rem 1rem #00fd04, .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.diamond {
    color: #e69a00;
    font-size: 24px;
    margin-right: 10px;
}

.disabled {
    background-color: rgb(157, 157, 157);
}

.disabled:hover {
    background-color: rgb(157, 157, 157);
}

.earn-interest {
    align-items: center;
    display: flex;
    background-color: #F5F8FA;
    border: 1px solid #E3E9ED;
    border-radius: 4px;
    color: #FBC356;
    font-size: 13px;
    margin-top: 20px;
    padding: 20px;
}

.buying-power-div {
    padding: 10px;
    font-size: 13px;
    color: grey
}

.chart-price {
    font-size: 32px;
    
}

.too-broke {
    position: fixed;
    top: 150px;
    right: 150px;
    height: 400px;
    width: 400px;
    font-size: 20px;
    font-family: 'Montserrat';
    
}

.topleft-individualprice-container {
    display: flex;
    flex-direction: column;
    justify-content:start
}

.key-stats-container {
    display: flex;
    flex-direction: column;
    margin: 3px;
}

.key-stat-title-div {
    padding: 20px 0 20px  0;
    border-top: solid 1px #E3E9ED;
    border-bottom: solid 1px #E3E9ED;
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 500;
    line-height: 32.4px;
    font-style: normal;
    margin-top: 15px;
}

.list-supported-stocks {
    color: #0055fe;
    cursor: pointer;
    margin-top: 8px;
    font-size: 13px;
}

.not-supported {
    background-color: gray;
}

.not-supported:hover {
    background-color: gray;
}

.order-selector {
    align-items: center;
    display: flex;
    
    justify-content: flex-start;
    border-bottom: solid 1px #E3E9ED;
}

.purchase-container {
    position: fixed;
    width: 270px;
    height: fit-content;
    /* padding: 20px; */
    top: 100px;
    right: 150px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.02) 0px -12px 24px 0px, rgba(0, 0, 0, 0.06) 0px 12px 24px 0px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    border: solid 1px rgb(227, 233, 237);
    border-radius: 4px;
}

.purchase-buy-div {
    padding: 20px;
}

.left-order-type-div {
    padding: 10px;
    font-size: 13px;
    margin-left: 13px;
}

.right-order-type-div {
    padding: 10px;
    margin-right: 10px;
    font-size: 13px;
}



.left-buy-in--div {
    padding: 10px;
}

.left-est-div {
    padding: 10px;
    font-size: 13px;
    font-weight: bold;
    margin-left: 13px
}

.right-est-div {
    padding: 10px;
    text-align: right;
    margin-right: 5px;
    font-size: 13px;
    font-weight: bold;

}



.left-shares-div {
    padding: 10px;
    font-size: 14px;
    margin-left: 13px;
}

.right-shares-div {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin-right: 5px;
}

.sell-div {
    padding: 20px;
}

.shares-available-div {
    padding: 10px;
    font-size: 10pt;
}

.shares-input {
    text-align: right;
    border: none;
    height: 27px;
    background-color: rgb(237, 237, 237);
    border-radius: 5px;
}
.shares-input::-webkit-inner-spin-button,
.shares-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.confirmation-title {
    font-weight: bold;
    font-size: 14px;
}

.shares-input:focus {
    border: 1px solid rgb(0,200,6);
    outline: none;
}

.stat-value-container {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.stat-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 13px;
}

.supported-stocks {
    align-items: center;
    background-color: white;
    border: solid 1px rgb(227, 233, 237);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px -12px 24px 0px, rgba(0, 0, 0, 0.06) 0px 12px 24px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    right: 300px;
    position: absolute;
    padding: 15px 10px;
    row-gap: 10px;
    top: 150px;
    width: 180px;
}

.supported-ticker {
    color: #0055fe;
    margin: 10px 10px;
}

.actual-stat {
    font-weight: bold;
}

.true-stat {
    font-size: 13px;
}

.stock-page-main-container {
    margin-left: 120px;
    margin-bottom: 80px;
    width: 750px;
}

.stock-timeline {
    border: none;
    background-color: white;
    font-size: 14px;
    font-weight: bold;

}

.individualstockpage-timeline {
    display: flex;
    gap: 8px;
}

.stock-timeline:hover {
    color: rgb(0,200,6);
}

.stat-value-container {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.stat-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.stock-page-main-container {
    margin-left: 120px;
    width: 750px;
}

.stock-timeline {
    border: none;
    background-color: white;
    font-size: 14px;
}

.stock-timeline:hover {
    color: rgb(0,200,6);
}

.transaction-button-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
}

.watchlist-button {
    width: 200px;
    height: 40px;
    margin: 10px;
    background-color: white;
    color: rgb(0,200,6);
    border-radius: 50px;
    border: 1px solid rgb(0,200,6);
    font-size: 10pt;
    font-weight: bold;
}

.watchlist-button:hover {
    background-color: rgb(173, 252, 176);
}


.news-stat-title-div {
    padding: 20px 0 20px  0;
    border-bottom: solid 1px #E3E9ED;
    font-size: 20pt;
}

.news-navlink {
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-decoration: none;
    font-size: 15px;
    border-radius: 5px;
}

.news-navlink:hover {
    background-color: rgb(234, 234, 234);
}

.individual-news-div {
    display: flex;
    justify-content: flex-start;
    font: 10px;
}

.transaction-title-div {
    padding: 20px 0 20px  0;
    border-bottom: solid 1px #E3E9ED;
    font-size: 20pt;
    margin-bottom: 15px;
    padding-top: 30px;
}

/* HIDDEN CONDITIONAL CLASS */

.hidden {
    display: none;
}
