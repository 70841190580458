.left-signup {
    display: flex;
    background-color: #005F32;
    /* width: 50%; */
    flex-direction: column;
    border-right: 1px black solid;
    height: 95vh;
}

.signup-logo {
    color: rgb(227, 233, 237);
    height: 35px;
    font-size: 30px;
}

.h1-invest {
    color: rgb(195,245,60);
    color-scheme: light;
    font-size: 72px;
    font-style: normal;
    font-family: sans-serif;
    font-weight: 400;
    line-height: 87.5px;
}

.darkgreen-chart {
    width: 100%;
}

.right-signup {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    padding: 50px;
}

.signup-top-left {
    height: 50%;
    margin-right: 30%;
}

.signup-container {
    height: 100%;
    width: 100%;
    display: flex;
}

.signup-form {
    display: flex;
    flex-direction: column;
    gap: 20px
}


.first-last {
    width: 280px;
    height: 50px;
    border: 1px solid lightgray;
    font-size: 18px;
    text-indent: 10px;
}

.signup-email {
    width: 590px;
    height: 50px;
    border: 1px solid lightgray;
    font-size: 18px;
    text-indent: 10px;
    
}


.already-started{
    font-size: 14px;
}

.submit-signup {
    height: 44px;
    width: 180px;
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 24px;
    cursor: pointer;
}

/* Hidden class toggle must be at bottom of this stylesheet to override other rules */

.hidden {
    display: none;
}
