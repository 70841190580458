* {
    font-family: Capsule Sans Text,-apple-system,BlinkMacSystemFont,sans-serif;
}

.account {
    margin-bottom: 2px;
}

.break {
    border: none;
    border-top: 1px solid #E3E9ED;
    height: 0px;
    margin: 0;
}

.buying-power {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    font-weight: bold;
}

.chart-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100%;
    max-height: 400px;
    height: 100%;
    text-decoration: none;
    width: 1500px;
    height: 100%
}

.green-triangle {
    color: rgb(0, 200, 5);
    width: 12px;
    height: 12px;
}

.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    max-width: 95%;
    width: 100%;

}

.homepage-navigationcontainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: white;
}

.homepage-rightcontainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    max-width: 100%;
    width: 100%;
}

.home-nav {
    text-decoration: solid;
    font-size: 13px;
    font-weight: 400;
    font-family: sans-serif;
    font-weight: bold;
    cursor: pointer;
}

.homepage-container {
    display: flex;
    justify-content: flex-end;
    column-gap: 30px;
    max-width: 100%;
    height: 100%;
}

.home-nav:hover {
    color: rgb(0,200,6)
}

.indexes-container {
    align-items: center;
    background-color: #F5F8FA;
    border: 1px solid #E3E9ED;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin: 5px 0px;
    max-width: 100%;
    width: 100%;
}

.index-and-price {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.indexes {
    color: black;
    font-size: 15px;
}

.index-price {
    font-weight: 400;
    margin-left: 10px;
}

.leftside-container {
    height: 100%;
    width: 1000px;
}

.line-chart-container {
    height: 100%;
    max-width: 100%;
}

.news-card {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    padding: 25px;
}

.news-card:hover {
    background-color: #F5F8FA;
}

.news-image {
    height: 85px;
    width: 140px;
    position: relative;
    border-radius: 7px;
}

.news-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 15px;
}

.news-cardleft {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-content: center;
    max-width: 700px;
    justify-content: center;
    font-weight: bold;
}

.news-header {
    margin-bottom: 0;
}

.portfolio-container {
    max-width: 1400px;
    min-width: 721px;
    width: 70%;
}

.price {
    font-size: 32px;
    font-family: Sans-Serif;
    color: black;
    font-weight: 400;
}

.profile-timeline {
    border-style: none;
    background-color: white;
    padding: 10px;
    color: black;
    cursor: pointer;
    line-height: 20px;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
}



.profile-timeline:hover {
    color: rgb(0,200,6)
}

.section-header {
    font-size: 24px;
    font-weight: 500;
}

.search-bar {
    margin-left: 100px;
    font-size: 13px;
    
}

.searching {
    height: 34px;
    width: 438px;
    padding-left: 12px;
    line-height: 20px;
    padding-right: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2); /* 1px width border with 20% opacity black color */
}

.source {
    font-size: 13px;
    font-weight: 700;
}

.story-ticker {
    font-size: 13px;
    font-weight: 700;
}

.time-units {
    font-size: 13px;
    font-weight: 400;
    margin-left: 2px;
}

.title {
    font-size: 15px;
    font-weight: 400;
    max-width: 95%;
}

.watchlist-container {
    display: flex;
    border: 1px solid #E3E9ED;
    border-radius: 3px;
    flex-direction: column;
    height: 100%;
    margin-left: 70px;
    max-width: 339px;
    min-width: 239px;
    margin: 36px 24px 24px 24px;
    overflow-y: scroll;
    width: 100%;
    /* Hide scrollbar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.suggestions-container {
    position: absolute;
    top: 55px; /* adjust this value to position the suggestions container */
    left: 160px;
    width: 445px;
    z-index: 1;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 200px;
    padding: 0.5rem;
    border-radius: 4px
}

.suggestions {
cursor: pointer;
padding: 0.25rem 0.5rem;
border-radius: 4px;
}

.suggestions:hover {
background-color: #f2f2f2;
}

.watchlist-container::-webkit-scrollbar {
    /* Hide scrollbar */
    display: none;  /* Safari and Chrome */
}

.loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1080px) {
    .homepage-container {
        justify-content: flex-start;
    }
}

.underprice-container {
    display: flex;
    justify-content: start;
    
}

.profit {
    margin-left: 5px;
    color: rgb(0,200,6);
    font-size: 13px;
}

.loss {
    margin-left: 5px;
    color: red;
    font-size: 13px;
}

.price-transition,
.profit-loss-transition {
  transition: all 1s ease-in-out; /* Change the transition duration and timing function as desired */
}

.dropdown-menu {
    
    position: absolute;
    top: 50px;
    right: 40px;
    background-color: white;
    border: 2px solid #E3E9ED;
    width: 296px;
    height: 400px;
    z-index: 1000;
    border-radius: 12px;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.02) 0px -12px 24px 0px, rgba(0, 0, 0, 0.06) 0px 12px 24px 0px
}




.dropdown-nav {
    text-decoration: solid;
    font-size: 13px;
    font-weight: 500;
    font-family: sans-serif;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
}

.dropdown-nav:hover{
    background-color: #F5F8FA;
}

.dropper {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: start;    
    margin-left: 15px;
}


.dropdown-container-wrapper:hover {
    cursor: pointer;
    align-items: start;    
    margin-left: 15px;
}


.dropdown-container-wrapper:hover {
    cursor: pointer;
    
  }

.dropdown-icon {
    height: 20px;
    width: 20px;
    padding-right: 25px;
    line-height: 15px;
}

.dropdown-hand {
    height: 22px;
    padding-right: 21px;
    line-height: 15px;
}

.dropdown-logout {
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
    font-family: sans-serif;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: white;
    border-style: none;
}

.dropdown-logout:hover {
    background-color:  #F5F8FA;
}
