.buy {
    color: #00C805;
    font-weight: 700;
}

.sell {
    color: #FF5000;
    font-weight: 700;
}

.header {
    margin-bottom: 20px;
}

.history-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    max-width: 678px;
    width: 100%;
}

.name-container {
    font-size: 32px;
    font-family: sans-serif;
    font-weight: 500;
}

.recent-container {
    font-size: 24px;
    font-family: sans-serif;
    margin-top: 30px;
}

.ticker-id {
    font-weight: 700;
    margin-bottom: 5px;
}

.transaction-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    margin: 20px 0px;
    width: 100%;
}

.hover-transaction:hover {
    background-color: #F5F8FA;
}
.transaction-card:hover {
    background-color: #F5F8FA;
}


/* MEDIA QUERIES */

@media screen and (min-width: 760px) {
    .history-container {
        margin-left: 15%;
    }
}
