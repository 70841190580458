.watchlists-container-home {
  height: 85vh;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  position: sticky;
}


.watchlists-header-home {
  display: flex;
  justify-content: space-between;
  padding: 20px 27px;
  border-bottom: 1px solid rgba(99, 99, 99, 0.2);
}

.watchlists-title-home {
  display: flex;
  align-items: end;
  text-align: center;
  font-weight: 700;
}

.add-watchlist-button-home {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: white;
  cursor: pointer;
  font-size: large;
  text-align: center;
  width: 18px;
  height: 18px;
}

.add-watchlist-button-home:hover {
  background-color: rgb(194, 194, 194);
  border-radius: 2px;
}

.watchlist-item-name-home {
  text-align: left;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;
}

.watchlists-list-home {
  border-bottom: 1px solid rgba(99, 99, 99, 0.2);
}

.watchlist-item-home {
  display: flex;
  justify-content: space-between;
  padding: 0px 23px;
  height: 59px;
  align-items: center;
  position: relative;
}

.watchlist-item-home:hover {
  cursor: pointer;
  background-color: rgb(245,248,250);
  border-radius: 2px;
}
.watchlist-edit-button-home {
  padding: 5px;
  border-radius: 5px;
  position: relative;
  right: -30px;
  transition: all 0.3s ease-in-out;
}
.watchlist-edit-button-home:hover {
  background-color: rgb(214, 229, 240);
}

.create-watchlist-form-home {
  padding: 10px 27px;
  border-bottom: 1px rgba(99, 99, 99, 0.2);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.create-watchlist-input-name-home {
  height: 35px;
  padding-left: 10px;
  background-color: rgb(245,248,250);
  border: 1px solid white;
}

.create-watchlist-input-name-home::placeholder {
  color: rgb(190,198,202);
}

.create-watchlist-input-name-home:hover {
  background-color: white;
  border: 1px rgb(219, 219, 219) solid;
  border-radius: 5px;
}

.create-watchlist-input-name-home:focus {
  border: 1px solid rgb(0,200,5);
  background-color: white;
  outline: none;
  border-radius: 5px;
}

.create-watchlist-form-buttons-home {
  display: flex;
  justify-content: space-around;
}

.create-watchlist-form-name-home {
  display: flex;
  justify-content: center;
}

.create-watchlist-cancel-button-home {
  background-color: white;
  border: 1px solid rgb(0,200,5);
  color: rgb(0,200,5);
}

.create-watchlist-cancel-button-home:hover {
  background-color: rgb(229,249,230);
}

.create-watchlist-create-button-home {
  background-color: rgb(0,200,5);
  border: none;
  color: white;
}

.create-watchlist-create-button-home:hover {
  background-color: rgb(0,180,5);
}
.create-watchlist-create-button-home,
.create-watchlist-cancel-button-home {
  padding: 10px 25px;
  border-radius: 25px;
  font-weight: bolder;
}

.watchlist-list-edit-dropdown-home {
  flex-direction: column;
}

.showModal {
  display: flex;
  z-index: 9999;
}

.watchlist-list-edit-menu-items-home {
  display: flex;
  height: 30px;
  align-items: center;
  padding-left: 10px;
  background-color: white;
}

.watchlist-list-edit-menu-items-home:hover {
  background-color: rgb(245,248,250);
}

.watchlist-modal-button {
  z-index: 9999;


}

.watchlist-stock-container-home {
  height: 60px;
  padding: 0px 23px;
  display: flex;
}

.watchlist-stock-container-home:hover {
  background-color: rgb(245,248,250);
  cursor: pointer;
}

.watchlist-stock-name-home {
  display: flex;
  align-items: center;
  width: 35%;
  max-width: 100px;
  font-size: small;
  font-weight: bold;
}

.watchlist-graph-home {
  display: flex;
  align-items: center;
  width: 30%
}

.watchlist-stock-price-daily-container {
  display: flex;
  flex-direction: column;
  width: 35%
}

.watchlist-stock-price-home {
  font-size: small;
  font-weight: 700;
  display: flex;
  align-items: center;
  height: 50%;
  justify-content: end;
}

.watchlist-stock-daily-home {
  font-size: small;
  font-weight: 700;
  display: flex;
  align-items: start;
  height: 50%;
  justify-content: end;
  color: rgb(0,183,5);
}

.watchlist-stock-daily-home-loss {
  font-size: small;
  font-weight: 700;
  display: flex;
  align-items: start;
  height: 50%;
  justify-content: end;
  color: rgb(255,80,0);
}

.watchlist-graph-home {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.empty-watchlist-container {
  padding: 0px 24px;
  font-size: small;
  border-bottom: rgba(99, 99, 99, 0.2);
}

.empty-watchlist-container-message {
  color: rgb(180, 180, 180)
}

.icon-rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}
