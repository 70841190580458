.watchlists-container {
  height: 85vh;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  position: sticky;
}


.watchlists-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 27px;
  border-bottom: 1px solid rgba(99, 99, 99, 0.2);
}

.watchlists-title {
  display: flex;
  align-items: end;
  text-align: center;
  font-weight: 700;
}

.add-watchlist-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: white;
  cursor: pointer;
  font-size: large;
  text-align: center;
  width: 18px;
  height: 18px;
}

.add-watchlist-button:hover {
  background-color: rgb(194, 194, 194);
  border-radius: 2px;
}

.watchlist-item-name {
  text-align: left;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;
}

.watchlists-list {
  border-bottom: 1px solid rgba(99, 99, 99, 0.2);
}

.watchlist-item {
  display: flex;
  justify-content: space-around;
  height: 59px;
  align-items: center;
  position: relative;
}

.watchlist-item:hover {
  cursor: pointer;
  background-color: rgb(245,248,250);
  border-radius: 2px;
}
.watchlist-edit-button {
  padding: 5px;
  border-radius: 5px;
}
.watchlist-edit-button:hover {
  background-color: rgb(214, 229, 240);
}

.create-watchlist-form {
  padding: 10px 27px;
  border-bottom: 1px solid rgba(99, 99, 99, 0.2);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.create-watchlist-input-name {
  height: 35px;
  padding-left: 10px;
  background-color: rgb(245,248,250);
  border: 1px solid white;
}

.create-watchlist-input-name::placeholder {
  color: rgb(190,198,202);
}

.create-watchlist-input-name:hover {
  background-color: white;
  border: 1px rgb(219, 219, 219) solid;
  border-radius: 5px;
}

.create-watchlist-input-name:focus {
  border: 1px solid rgb(0,200,5);
  background-color: white;
  outline: none;
  border-radius: 5px;
}

.create-watchlist-form-buttons {
  display: flex;
  justify-content: space-around;
}

.create-watchlist-form-name {
  display: flex;
  justify-content: center;
}

.create-watchlist-cancel-button {
  background-color: white;
  border: 1px solid rgb(0,200,5);
  color: rgb(0,200,5);
}

.create-watchlist-cancel-button:hover {
  background-color: rgb(229,249,230);
}

.create-watchlist-create-button {
  background-color: rgb(0,200,5);
  border: none;
  color: white;
}

.create-watchlist-create-button:hover {
  background-color: rgb(0,180,5);
}
.create-watchlist-create-button,
.create-watchlist-cancel-button {
  padding: 10px 25px;
  border-radius: 25px;
  font-weight: bolder;
}

.watchlist-list-edit-dropdown {
  position: absolute;
  top: 75%;
  left: 77%;
  z-index: 1;
  display: none; /* Add this line */
  flex-direction: column;
  background-color: white;
  width: 200px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 12px;

}

.showModal {
  display: flex;
}

.watchlist-list-edit-menu-items {
  display: flex;
  height: 30px;
  align-items: center;
  padding-left: 10px;
  background-color: white;
}

.watchlist-list-edit-menu-items:hover {
  background-color: rgb(245,248,250);
}

.create-watchlist-error {
  color: red;
  font-size: small;
}
